.statistic-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 15px;
}

@media screen and (max-width: 768px) {
  .statistic-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
