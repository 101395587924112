table {
  width: 100%;
}

.isSortable:hover,
.isCurrentSort {
  border-color: #7a7a7a !important;
}

.tableIcon {
  margin-left: 0.5rem;
}

.currentPage {
  color: #2e323a !important;
  border-color: #6c757d !important;
  background-color: #fff;
}

.level {
  padding: 20px;
}
